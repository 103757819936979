<template>
  <div class="input">
    <h2 class="input__title">Inputs</h2>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input Default</h3>
      <Input v-model="textValue" :placeholder="'Название проблемы...'" />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input Props</h3>
      <ul class="input__list">
        <li class="input__item"><span>value</span> - информация при редактировании</li>
        <li class="input__item"><span>label</span> - название текстового поля</li>
        <li class="input__item"><span>placeholder</span> - текст внутри текстового поля</li>
        <li class="input__item">
          <span>showInfo</span> - показать/скрыть блок информации о текстовом поле
        </li>
        <li class="input__item"><span>tooltip</span> - информационный текст о текстовом поле</li>
        <li class="input__item"><span>maxlength</span> - максимальное число символов</li>
        <li class="input__item"><span>minlength</span> - минимальное число символов</li>
        <li class="input__item"><span>disabled</span> - сделать текстовое поле неактивным</li>
      </ul>
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input with Label</h3>
      <Input
        v-model="textValue"
        :label="'Название проблемы'"
        :placeholder="'Название проблемы...'"
      />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Input with Info</h3>
      <Input
        v-model="textValue"
        :label="'Название проблемы'"
        :placeholder="'Название проблемы...'"
        :showInfo="true"
        :tooltip="'Уточняющая информация'"
      />
    </div>
    <h2 class="input__title input__title_textarea">Textarea</h2>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea Default</h3>
      <TextArea
        :label="textAreaExample.label"
        :id="textAreaExample.id"
        :value="textAreaExample.value"
        :placeholder="textAreaExample.placeholder"
        :disabled="textAreaExample.disabled"
        :row-height="textAreaExample['row-height']"
        :rows="textAreaExample.rows"
        :auto-grow="textAreaExample['auto-grow']"
      />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea Props</h3>
      <ul class="input__list">
        <li class="input__item">
          <span>label </span>
          <input type="text" v-model="textAreaExample.label" />
          - название текстового поля
        </li>

        <li class="input__item">
          <span>id </span>
          <input type="text" v-model="textAreaExample.id" />
          - уникальный id, необходимый для связывания текстовой области с названием
        </li>
        <li class="input__item">
          <span>value </span> <input type="text" v-model="textAreaExample.value" /> - информация при
          редактировании
        </li>

        <li class="input__item">
          <span>placeholder </span> <input type="text" v-model="textAreaExample.placeholder" /> -
          текст внутри текстового поля
        </li>
        <li class="input__item">
          <span>disabled </span> <input type="checkbox" v-model="textAreaExample.disabled" />
          - сделать текстовое поле неактивным (default: false)
        </li>

        <li class="input__item">
          <span>row-height </span> <input type="text" v-model="textAreaExample['row-height']" />
          - значение высоты для каждого ряда в px (default: 24)
        </li>
        <li class="input__item">
          <span>rows </span> <input type="text" v-model="textAreaExample.rows" /> - количество строк
          по умолчанию (default: 5)
        </li>
        <li class="input__item">
          <span>auto-grow </span> <input type="checkbox" v-model="textAreaExample['auto-grow']" /> -
          автоматическое увеличение текстовой области в зависимости от объема текста (default: true)
        </li>
      </ul>
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea Default</h3>
      <Textarea v-model="textareaValue" :placeholder="'Суть проблемы...'" />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea Props</h3>
      <ul class="input__list">
        <li class="input__item"><span>value</span> - информация при редактировании</li>
        <li class="input__item">
          <span>unique</span> - уникальный ключ для вставки файлов в текстовое поле
        </li>
        <li class="input__item"><span>label</span> - название текстового поля</li>
        <li class="input__item"><span>placeholder</span> - текст внутри текстового поля</li>
        <li class="input__item">
          <span>showInfo</span> - показать/скрыть блок информации о текстовом поле
        </li>
        <li class="input__item"><span>tooltip</span> - информационный текст о текстовом поле</li>
        <li class="input__item"><span>maxlength</span> - максимальное число символов</li>
        <li class="input__item"><span>minlength</span> - минимальное число символов</li>
        <li class="input__item"><span>disabled</span> - сделать текстовое поле неактивным</li>
      </ul>
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea with Label</h3>
      <Textarea
        v-model="textareaValue"
        :label="'Суть проблемы'"
        :placeholder="'Суть проблемы...'"
      />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Textarea with Info</h3>
      <Textarea
        v-model="textareaValue"
        :label="'Суть проблемы'"
        :placeholder="'Суть проблемы...'"
        :showInfo="true"
        :tooltip="'Уточняющая информация'"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/modules/UiKit/components/Inputs/Input";
import Textarea from "@/modules/UiKit/components/Inputs/Textarea";
import TextArea from "@/aopV2/components/UIComponents/Input/Text/TextArea/TextArea";

export default {
  name: "InputPage",
  mixins: [],
  props: [],
  components: { TextArea, Input, Textarea },
  data() {
    return {
      textValue: "",
      textareaValue: "",
      textAreaExample: {
        label: "Textarea Label",
        id: Math.round(Math.random() * 1000000),
        value: "Значение текстового поля",
        placeholder: "Placeholder...",
        disabled: false,
        "row-height": 24,
        rows: 5,
        "auto-grow": true
      }
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
